<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'
import workflow from '@/workflows/case'

export default {
  name: 'Case',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.cases
      },
      set (payload) {
        this.$store.commit('setCases', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$store.commit('setBrand', 'Market Abuse Risk Insights')
    this.permissions.read = ac.can(this.user.acgroups).readAny('case').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('case').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'case',
      nav: [
        { name: 'Cases', text: 'Cases' },
        { name: 'CasesDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Cases',
      permissions: {
        add: false,
        read: false
      },
      searchProperties: ['name', 'description', 'tailoring', 'products', 'criminal'],
      staticDataProperties: [
        { name: 'Coverage', property: 'coverage', graph: 'coverage' },
        { name: 'Product Types', property: 'producttype', graph: 'producttypes' },
        { name: 'Asset Class', property: 'assetclass', graph: 'assetclasses' },
        { name: 'Control risks', property: 'controlrisk', graph: 'controlrisks' },
        { name: 'MAR risks', property: 'risk', graph: 'risks' },
        { name: 'Year', property: 'year', direct: true },
        { name: 'Workflow', property: 'workflow', graph: 'workflow' }
      ],
      sortDirection: 'desc',
      sortProperty: 'created_at',
      sortPropertyOptions: [
        { value: 'name', text: 'title' },
        { value: 'created_at', text: 'date created' },
        { value: 'date_published', text: 'date published' },
        { value: 'start', text: 'date case started' },
        { value: 'end', text: 'date case ended' },
        { value: 'loss', text: 'cost' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = await this.$Amplify.API.get('cosmos', '/cases/staticdata')
        let year = this.rows.map(x => x.year)
        year = _.uniq(year)
        year = year.map(x => {
          return { id: x, name: x }
        })
        staticData.workflow = workflow
        staticData.year = year
        this.staticData = staticData
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/cases/limit/${limit}/offset/${offset}`)
          response.forEach(x => {
            const w = workflow.find(y => y.id === x.status_id)
            x.workflow = [{
              id: x.status_id,
              name: w.name
            }]
            if (x.publications.length > 0) {
              x.date_published = x.publications[0].dateTime
              const d = new Date(x.date_published)
              x.year = d.getFullYear()
            } else {
              x.date_published = null
              x.year = 1970
            }
          })
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
